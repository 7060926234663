<template>
  <div v-loading="loading">
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">应收统计</span>

        <!-- <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        > -->
      </div>

      <el-table
        max-height="550"
        :summary-method="getSummaries"
        show-summary
        border
        :data="receivableStatisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="company"
          min-width="100"
          label="公司"
          fixed="left"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.company | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="agreementName"
          label="合同名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="projectManager"
          min-width="100"
          label="项目经理"
          fixed="left"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="businessManager"
          min-width="100"
          label="商务负责人"
          fixed="left"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectType"
          min-width="100"
          label="项目类型"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.projectType | dict(dictData.businessLines) }}
          </template></el-table-column
        >
        <el-table-column
          prop="customerName"
          label="客户名称"
          align="center"
          min-width="240"
        ></el-table-column>
        <el-table-column prop="projectStartDate" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>项目实际开始日期</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的项目实际开始日期</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectStartDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="projectRealAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>实际项目金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的实际项目金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectRealAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="amountReceived" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的，项目收入中已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountReceived | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="amountReceivable" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应收金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情中的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountReceivable | applyAmount }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="receivableSizeChange"
        @current-change="receivableCurrentChange"
        :current-page="receivableInfo.pageNow"
        :page-sizes="[20, 50, 100, 150]"
        :page-size="receivableInfo.pageSize"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="receivableInfo.total"
      >
      </el-pagination>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">应付统计</span>
        <!-- <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        > -->
      </div>
      <!-- :summary-method="getSummariesHandle"
        show-summary -->
      <el-table max-height="550" border :data="handleStatisticsList">
        <el-table-column
          prop="company"
          min-width="100"
          label="公司"
          fixed="left"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.company | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="contractName"
          label="合同名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          prop="contractType"
          min-width="100"
          label="合同类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractNumber"
          min-width="100"
          label="合同编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="supplierName"
          min-width="240"
          label="供应商名称"
          sortable
          align="center"
        ></el-table-column>
        <el-table-column prop="signingDate" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>签订日期</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>合同详情里的签订日期</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.signingDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="actualProjectAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>实际项目金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的实际项目金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualProjectAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="amountPaid" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>付款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <!-- <div>项目详情里的，项目收入中已收款金额</div> -->
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountPaid | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="amountDue" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应付金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <!-- <div>项目收入中的合同金额</div> -->
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountDue | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="handleInfo.pageNow"
        :page-sizes="[20, 50, 100, 150]"
        :page-size="handleInfo.pageSize"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="handleInfo.total"
      >
      </el-pagination>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目统计表.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
        businessLines: [],
      },
      receivableStatisticsList: [], // 应收统计
      handleStatisticsList: [], // 应付统计
      receivableInfo: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      receivableAll: {
        amountReceivable: 0,
        amountReceived: 0,
        projectRealAmount: 0,
      },
      handleInfo: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      isDialog: false,
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
    this.init()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init() {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryReceivablesReport(this.receivableInfo),
        this.$api.statistics.queryPaymentReport(this.handleInfo),
      ])
        .then(res => {
          this.receivableStatisticsList =
            res[0]?.data?.receivableStatisticsList?.receivableStatisticsList?.records || []
          this.receivableInfo.total =
            res[0]?.data?.receivableStatisticsList?.receivableStatisticsList?.total
          this.receivableAll.amountReceivable =
            res[0]?.data?.receivableStatisticsList?.amountReceivable || 0
          this.receivableAll.amountReceived =
            res[0]?.data?.receivableStatisticsList?.amountReceived || 0
          this.receivableAll.projectRealAmount =
            res[0]?.data?.receivableStatisticsList?.projectRealAmount || 0

          this.handleStatisticsList = res[1]?.data?.receivableReportList?.records || []
          this.handleInfo.total = res[1]?.data?.receivableReportList?.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    async receivableSizeChange(val) {
      this.receivableInfo.pageSize = val
      const res = await this.$api.statistics.queryReceivablesReport(this.receivableInfo)
      this.receivableStatisticsList =
        res?.data?.receivableStatisticsList?.receivableStatisticsList?.records || []
      this.receivableInfo.total =
        res?.data?.receivableStatisticsList?.receivableStatisticsList?.total
      this.receivableAll.amountReceivable =
        res?.data?.receivableStatisticsList?.amountReceivable || 0
      this.receivableAll.amountReceived = res?.data?.receivableStatisticsList?.amountReceived || 0
      this.receivableAll.projectRealAmount =
        res?.data?.receivableStatisticsList?.projectRealAmount || 0
    },
    async receivableCurrentChange(val) {
      this.receivableInfo.pageNow = val
      const res = await this.$api.statistics.queryReceivablesReport(this.receivableInfo)
      this.receivableStatisticsList =
        res?.data?.receivableStatisticsList?.receivableStatisticsList?.records || []
      this.receivableInfo.total =
        res?.data?.receivableStatisticsList?.receivableStatisticsList?.total
      this.receivableAll.amountReceivable =
        res?.data?.receivableStatisticsList?.amountReceivable || 0
      this.receivableAll.amountReceived = res?.data?.receivableStatisticsList?.amountReceived || 0
      this.receivableAll.projectRealAmount =
        res?.data?.receivableStatisticsList?.projectRealAmount || 0
    },
    async handleSizeChange(val) {
      this.handleInfo.pageSize = val
      const res = await this.$api.statistics.queryPaymentReport(this.handleInfo)
      this.handleStatisticsList = res?.data?.receivableReportList?.records || []
      this.handleInfo.total = res?.data?.receivableReportList?.total
    },
    async handleCurrentChange(val) {
      this.handleInfo.pageNow = val
      const res = await this.$api.statistics.queryPaymentReport(this.handleInfo)
      this.handleStatisticsList = res?.data?.receivableReportList?.records || []
      this.handleInfo.total = res?.data?.receivableReportList?.total
    },
    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总合计'
          return
        } else if (index == 9) {
          sums[index] = Number(this.receivableAll.projectRealAmount.toFixed(2)).toLocaleString()
        } else if (index == 10) {
          sums[index] = Number(this.receivableAll.amountReceived.toFixed(2)).toLocaleString()
        } else if (index == 11) {
          sums[index] = Number(this.receivableAll.amountReceivable.toFixed(2)).toLocaleString()
        }
      })
      return sums
    },
    getSummariesHandle(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getType() {
      // this.$api.dict
      //   .listSysDictData('STATISTICS_YEAR', true)
      //   .then(res => {
      //     this.dictData.yearList = res.data
      //     const { dictVal = '' } = res.data[0] || null
      //     this.sectionInfo.yearStatisticsYear = dictVal
      //     this.sectionInfo.monthStatisticsYear = dictVal
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.project_individual {
  display: inline-block;
  text-align: center;
  width: 190px;
  font-size: 18px;
  .project_individual_title {
    font-size: 15px;
  }
  .project_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.el-tabs {
  .el-tabs__item {
    color: #000;
  }
}
</style>
